import {observer} from 'mobx-react'
import React from 'react'

import {Box} from '/ui-kit/Box'
import {CommentsBubble} from '/ui-kit/Icons'
import {Line} from '/ui-kit/Line'

import {CommentGroupFragment, ShortUserInfoFragment} from '/api-types'
import {AvatarImage} from '/ui-helpers/image-file'

export const CommentsBox = observer(({commentGroup, size = 'medium', placeholder, trusts, onClick}: {
    commentGroup: CommentGroupFragment
    trusts?: ShortUserInfoFragment[]
    size?: 'medium' | 'small'
    placeholder?: boolean
    onClick?: () => void
}) => {
    const iconSize = size === 'medium' ? 24 : 19
    const likesInfoCountStr = commentGroup.count.toString()
    const likesInfoWidth = 9
    return (
        <Box display="flex" onClick={onClick} justifyContent="center" alignItems="center">
            <CommentsBubble size={20} color="neutral/900"/>
            <Box width="s8"/>
            <Box position="relative" height="100%">
                <Box
                    position="absolute"
                    display="flex"
                    wrap="noWrap"
                    alignItems="center"
                    height="100%"
                >
                    <Box style={{
                        maxWidth: `${(likesInfoWidth * likesInfoCountStr.length).toString()}px`,
                        minWidth: `${(likesInfoWidth * likesInfoCountStr.length).toString()}px`,
                        width: `${(likesInfoWidth * likesInfoCountStr.length).toString()}px`,
                    }}>
                        <Line
                            role={size === 'medium' ? '14/24' : 'caption'}
                            weight={600}
                            color="neutral/900"
                            text={likesInfoCountStr}
                        />
                    </Box>
                    <Box width="s10"/>
                    {trusts && trusts.map((trust, i) =>
                        <div key={trust.id} style={{marginLeft: i > 0 ? '-3px' : '', zIndex: 10 - i}}>
                            <AvatarImage avatar={trust.user_image} size={20} bColor="white"/>
                        </div>
                    )}
                </Box>
            </Box>
        </Box>
    )
})
