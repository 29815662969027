/* eslint-disable */
import * as React from "react";
import { Atoms, atoms } from "/ui-kit/atoms.css";
import { icon } from "/ui-kit/styles.css";
export const SvgCommentsBubble = ({
  size = 24,
  width = size,
  height = size,
  color,
  className = "",
  ...rest
}: {
  size?: number,
  color?: Atoms["color"],
} & React.SVGProps<SVGSVGElement>) => {
  const props = {
    ...rest,
    width,
    height,
    className: `${icon} ${className} ${
      color
        ? atoms({
            color,
          })
        : ""
    }`,
  };
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.083 6.75h5.834M7 10h3M5.833 15.359h3.334l3.708 2.466a.83.83 0 0 0 1.292-.691v-1.775c2.5 0 4.166-1.667 4.166-4.167v-5c0-2.5-1.666-4.167-4.166-4.167H5.833c-2.5 0-4.166 1.667-4.166 4.167v5c0 2.5 1.666 4.167 4.166 4.167Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
