import {observable} from 'mobx'
import {observer} from 'mobx-react'
import React from 'react'

import {Box} from '/ui-kit/Box'
import {Button} from '/ui-kit/Button'
import {Divider} from '/ui-kit/Divider'
import {Gap} from '/ui-kit/Gap'
import {BubbleStarOutline, ChatBubble, Tag} from '/ui-kit/Icons'
import {Line} from '/ui-kit/Line'

import {LikeParentType, ProfileServiceWithReviewsFragment} from '/api-types'
import {CommentsBox} from '/comments/CommentsBox'
import {LikesBox} from '/likes/LikesBox'
import {tr} from '/locales'
import {navActions} from '/nav-actions'
import {nav} from '/nav-service'
import {FullService} from '/profile-services/frame'
import {ProfileServicePrice} from '/profile-services/ProfileServicePrice'
import {ReviewCard} from '/reviews/review-card'
import {openReviewsList} from '/reviews/reviews-list'
import {ShortReview} from '/reviews/short-review'
import {User} from '/ui-helpers/user-info'
import {UserProfileDataFragment} from '/user-profile'
import {DetailBlock} from '/user-profile/components'
import openStore from '/user-profile/open-store'

const fullServiceFrame = nav.registerFrameClass(FullService, {
    bottom: null,
    body: {
        type: 'data',
        background: 'neutral/0',
        block: observer(({model: {service}}) => <>
            <Box
                display="flex"
                flexDirection="column"
                py="s12" px="s16"
                background="green/50"
            >
                <Line text={service.title} role="h3" weight="bold" color="green/1000" />
                <Line text={service.shortDescription} overflow="wrap" role="16/22" weight="medium" color="green/1000" />
                <Gap size="s16" />
                <ProfileServicePrice service={service} />
                {service.priceDescription && <Line text={service.priceDescription} overflow="wrap" role="body2" weight="regular" color="neutral/900" />}
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                py="s12" px="s16"
                background="neutral/50"
            >
                {service.description && <Box
                    display="flex"
                >
                    <div dangerouslySetInnerHTML={{__html: service.description}}/>
                </Box>}
                <Gap size="s24" />
                {service.url && <Box
                    display="flex"
                >
                    <a
                        href={service.url.includes('https://') || service.url.includes('http://') ? service.url : 'https://' + service.url}
                        target="_blank" rel="noreferrer"
                        style={{
                            textDecoration: 'underline',
                            textDecorationColor: '#00B945',
                        }}
                    >
                        <Line text={service.urlTitle ? service.urlTitle : service.url} weight="bold" role="body1"
                              color="green/500"/>
                    </a>
                </Box>}
            </Box>
            <Box
                display="flex"
                py="s12" px="s16"
                background="neutral/50"
            >
                <Box
                    border="simple"
                    borderColor={service.likes_info?.is_like ? 'green/100' : 'neutral/200'}
                    display="flex"
                    background={service.likes_info?.is_like ? 'green/50' : 'neutral/75'}
                    py="s4"
                    pl="s8"
                    borderRadius="br16"
                    style={{paddingRight: 17}}
                >
                    <LikesBox
                        likesInfo={service.likes_info!}
                        parentType={LikeParentType.PROFILE_SERVICE}
                        trusts={service.likes_info!.trust_users.edges.map(({node}) => node)}
                        onSetLike={() => openStore.act({from: 'profile-review'})}
                        onOpenLikesList={() => openStore.act({from: 'profile-review'})}
                    />
                </Box>
                <Gap size="s24" />
                <Box
                    border="simple"
                    borderColor="neutral/200"
                    display="flex"
                    background="neutral/75"
                    py="s4"
                    pl="s8"
                    borderRadius="br16"
                    style={{paddingRight: 17}}
                >
                    <CommentsBox
                        commentGroup={service.comments}
                        trusts={service.comments.trust_users.edges.map(({node}) => node)}
                        onClick={() => openStore.act({from: 'profile-review'})}
                    />
                </Box>
            </Box>
            <Divider />
            <Box
                display="flex"
                flexDirection="column"
                px="s16"
            >
                <DetailBlock
                    title={tr.gen.t671345485}
                    counter={service.reviews.count}
                    items={service.reviews.edges.map(({node}) => node)}
                    render={r => <>
                        <ReviewCard
                            id={r.id}
                            like={
                                <Box
                                    border="simple"
                                    borderColor="neutral/100"
                                    display="flex"
                                    background="neutral/0"
                                    py="s4"
                                    pl="s8"
                                    borderRadius="br16"
                                    style={{paddingRight: 17}}
                                >
                                    <LikesBox
                                        likesInfo={r.likes_info}
                                        parentType={LikeParentType.REVIEW}
                                        placeholder
                                        onSetLike={() => openStore.act({from: 'profile-review'})}
                                        onOpenLikesList={() => openStore.act({from: 'profile-review'})}
                                    />
                                </Box>
                            }
                            onClickComments={() => openStore.act({from: 'profile-review'})}
                            comments={r.comments.count}
                            from="profile"
                            content={
                                <ShortReview
                                    review={r}
                                    show="author"
                                    from="fast-reviewed"
                                    openInFrame
                                    inCard
                                    gray
                                    expand
                                />
                            }/>
                    </>}
                    cardP="s0"
                    onAll={() => openReviewsList.act({
                        from: 'profile-service',
                        user: {id: service.author.id},
                        profileService: {id: service.id},
                        mode: 'profile-service',
                    })}
                    alsoText={tr.profile.reviewsAlso}
                />
                <Gap size="s32" />
            </Box>
        </>),
        afterScroll: observer(({model: {service}}: { model: { service: ProfileServiceWithReviewsFragment } }) => User.isMe(service.author) ? null : <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            pl="s16"
            pr="s16"
            pt="s4"
            pb="s4"
            background="neutral/0"
        >
            <Box display="flex" position="absolute" style={{
                height: '50px',
                minHeight: '50px',
                maxHeight: '50px',
                width: '100%',
                minWidth: '100%',
                top: '-50px',
                backgroundImage: 'linear-gradient(to bottom, transparent, white)',
            }}/>
            <Button
                startIcon={ChatBubble}
                text={tr.gen.t431234123}
                flex={1}
                look="primary"
                onClick={() => openStore.act({from: 'review-list'})}
                size="xl"
            />
            <Gap size="s8" />
            <Button
                startIcon={BubbleStarOutline}
                text=""
                flex={0}
                onClick={() => openStore.act({from: 'review-list'})}
                size="xl"
            />
        </Box>),
    },
})

export const fullService = navActions.registerNavAction<{
    from: 'profile' | 'link',
    profileServiceID: string,
}>({
    name: 'open-full-service',
    act: ({profileServiceID}) => {
        fullServiceFrame.load({profileServiceID})
    },
})
