/* eslint-disable */
import * as React from "react";
import { Atoms, atoms } from "/ui-kit/atoms.css";
import { icon } from "/ui-kit/styles.css";
export const SvgShare = ({
  size = 24,
  width = size,
  height = size,
  color,
  className = "",
  ...rest
}: {
  size?: number,
  color?: Atoms["color"],
} & React.SVGProps<SVGSVGElement>) => {
  const props = {
    ...rest,
    width,
    height,
    className: `${icon} ${className} ${
      color
        ? atoms({
            color,
          })
        : ""
    }`,
  };
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 11.679v6.536a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-6.536M16 7.785l-4-4-4 4M12 3.785V15.11"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
