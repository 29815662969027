/* eslint-disable */
import * as React from "react";
import { Atoms, atoms } from "/ui-kit/atoms.css";
import { icon } from "/ui-kit/styles.css";
export const SvgBubbleStarOutline = ({
  size = 24,
  width = size,
  height = size,
  color,
  className = "",
  ...rest
}: {
  size?: number,
  color?: Atoms["color"],
} & React.SVGProps<SVGSVGElement>) => {
  const props = {
    ...rest,
    width,
    height,
    className: `${icon} ${className} ${
      color
        ? atoms({
            color,
          })
        : ""
    }`,
  };
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#bubble-star-outline_svg__a)"
        strokeWidth={1.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M8.987 18.361a.9.9 0 0 1 .755.41l2.17 3.34a.91.91 0 0 1 .051.089l.037.069.037-.07a.91.91 0 0 1 .05-.087l2.17-3.341a.9.9 0 0 1 .755-.41h6.402a.988.988 0 0 0 .983-.983V2.586a.988.988 0 0 0-.983-.983H2.586a.988.988 0 0 0-.983.983v14.792c0 .539.444.983.983.983h6.401Z"
          stroke="currentcolor"
        />
        <path
          d="m15.676 7.812-1.967-.286-.88-1.783c-.458-.928-1.208-.928-1.666 0l-.88 1.783-1.967.286c-1.024.149-1.256.862-.514 1.585l1.423 1.387-.336 1.96c-.175 1.02.431 1.46 1.348.979l1.76-.925 1.758.925c.917.481 1.524.04 1.349-.98l-.336-1.959 1.423-1.387c.742-.723.51-1.436-.515-1.585Z"
          stroke="currentColor"
        />
      </g>
      <defs>
        <clipPath id="bubble-star-outline_svg__a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
