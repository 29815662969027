import {upperFirst} from 'lodash-es'
import {observer} from 'mobx-react-lite'
import React, {useState} from 'react'

import {isIos} from '/shared/env'
import {useStop} from '/shared/react'
import {toFixed} from '/shared/to-fixed'
import {entries} from '/shared/utils'

import {Box} from '/ui-kit/Box'
import {Button} from '/ui-kit/Button'
import {Gap} from '/ui-kit/Gap'
import { Rate } from '/ui-kit/Icons'
import {Line} from '/ui-kit/Line'
import {RatingStars} from '/ui-kit/RatingStars'

import {ReviewFragment, ShortUserInfoFragment, UserScoreFragment} from '/api-types'
import {openDialogWith} from '/chats/chat-messaging'
import {tr, trUtils} from '/locales'
import {dotDate} from '/locales/utils'
import {openReviewsList} from '/reviews/reviews-list'
import {session} from '/session-service'
import {ClampLines} from '/ui-elements/ClampLines'
import {Liemfy} from '/ui-elements/Liemfy'
import {AvatarImage} from '/ui-helpers/image-file'
import {AuthorInfo, JobLine} from '/ui-helpers/user-info'
import * as User from '/ui-helpers/user-info/user'
import {openProfile} from '/user-profile'

import {fullReview} from './full-review'

export const ShortReview = observer(({review, show, from, openInFrame, inCard = false, full, canWrite, onShowMore, onOpenReview, commonFriend, gray, expand}: {
    review: ReviewFragment
    commonFriend?: ShortUserInfoFragment
    show: 'target' | 'author' | 'both'
    from: 'fast-reviewed' | 'fast-reviews' | 'list'
    openInFrame?: boolean
    full?: boolean
    canWrite?: boolean
    onShowMore?: () => void
    onOpenReview?: () => void
    inCard?: boolean
    gray?: boolean
    expand?: boolean
}) => (
    <ShortReviewComponent
        full={full}
        canWrite={canWrite}
        target={show !== 'author' ? review.target : undefined}
        author={show !== 'target' ? review.author : undefined}
        commonFriend={commonFriend}
        meanGrade={review.mean_grade}
        description={review.description}
        title={review.service}
        grades={review.grades}
        timestamp={review.timestamp}
        onShowMore={onOpenReview || onShowMore}
        onOpenReview={!openInFrame
            ? undefined
            : onOpenReview
                ? onOpenReview
                : () => fullReview.act({
                id: review.id, from,
                info: {service: review.service, targetId: review.target.id, meanGrade: review.mean_grade}}
            )
        }
        lines={inCard ? 4 : 6}
        grow={inCard}
        expand={expand}
        gray={gray}
    />
))


export const ShortReviewComponent = ({
    target, author, meanGrade, grades, description, timestamp, title, onOpenReview, full: initFull = false, canWrite, commonFriend,
    lines = 4, onShowMore, grow, gray, expand,
}: {
    target?: ShortUserInfoFragment & UserScoreFragment
    author?: ShortUserInfoFragment & UserScoreFragment
    commonFriend?: ShortUserInfoFragment
    meanGrade: number
    title: string
    description: string
    timestamp: string
    grades: { quality: number, communication: number, price: number }
    onOpenReview?: () => void
    full?: boolean
    canWrite?: boolean
    onShowMore?: () => void
    lines?: number
    grow?: boolean
    expand?: boolean
    gray?: boolean
}) => {
    const [full, setFull] = useState(initFull)
    return (
        <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            flexShrink={1}
            minWidth="s0"
            position="relative"
            onClick={onOpenReview || (() => {
                setFull(true)
                onShowMore?.()
            })}
            p="s16"
            style={{height: expand ? '250px' : undefined}}
        >
            {author && <>
                <AuthorInfo
                    user={author}
                    commonFriend={commonFriend}
                    onClick={() => openProfile(author, 'full-review-author')}
                    onRatingClick={() => openReviewsList.act({from: 'review', user: author, mode: 'author'})}
                />
                <Gap size="s8" />
            </>}
            {target && <>
                <TargetInfo user={target} onClick={() => openProfile(target, 'full-review-specialist')}/>
                <Gap size="s12"/>
            </>}
            <ClampLines
                clamp={!full ? lines : 100000}
                forceShowMore={!!onShowMore}
                showMore={full ? undefined :
                    <div style={{
                        position: 'absolute',
                        right: description ? 0 : undefined,
                        left: description ? undefined : 0,
                        bottom: 0,
                        height: '24px',
                        display: 'flex',
                        alignItems: 'stretch',
                    }}>
                        {description && <div style={{
                            background: !gray ? 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 80%)' : 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #F5F5F5 80%)',
                            width: '25px',
                            alignSelf: 'stretch',
                        }}/>}
                        <div style={{background: !gray ? 'white' : '#F5F5F5', paddingTop: isIos ? '7px' : '4px', paddingRight: '10px'}}>
                            <Line role="13/18" weight={600} color="green/500" text={tr.buttons.showMore}/>
                        </div>
                    </div>}>
                <Liemfy>
                    <Line
                        role="body1"
                        weight={400}
                        text={upperFirst(description) || '\u00A0'}
                        overflow={full ? 'wrap' : 'wrapInline'}
                    />
                </Liemfy>
            </ClampLines>
            {(grow || expand) && <Box flex={1} />}
            {full && <>
                <Gap size="s8"/>
                {entries(tr.reviews.full.grades).map(([type, name]) => <React.Fragment key={type}>
                    <Gap size="s4"/>
                    <Box display="flex">
                        <Box flexBasis="s0" flexGrow={1} minWidth="s0">
                            <Line text={name} role="body2" weight={600}/>
                        </Box>
                        <Box flexBasis="s0" flexGrow={1} display="flex" minWidth="s0" pl="s8" alignItems="center">
                            <Box width="s10" mr="s8">
                                <Line text={grades[type].toString()} role="body2" weight={600} align="center"/>
                            </Box>
                            <RatingLines value={grades[type]}/>
                        </Box>
                    </Box>
                </React.Fragment>)}
            </>}
            <Gap size="s4"/>
            <Line
                role="16/22"
                weight={600}
                color="green/1000"
                text={upperFirst(title)}
                overflow="wrap"
            />
            <Gap size="s4"/>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
            >
                <Box display="flex" alignItems="center" overflow="hidden" height="s20">
                    <Box mr="s8" display="flex" alignItems="center" style={{paddingBottom: '2px'}}>
                        <RatingStars value={meanGrade} size="small"/>
                    </Box>
                    <Line role="16/22" weight="medium" text={toFixed(meanGrade)} />
                </Box>
                <Line
                    role="13/18"
                    weight={500}
                    color="neutral/500"
                    text={trUtils.dotDate(timestamp)}
                />
            </Box>
            {canWrite && full && target && !session.isMe(target) && <>
                <Gap size="s12"/>
                <Button
                    look="primary"
                    text={tr.reviews.full.connect}
                    onClick={() => openDialogWith.act({from: 'full-review-specialist', user: target})}
                />
            </>}
        </Box>
    )
}

const RatingLines = ({value}: {value: number}) => {
    const color = value > 3 ? '#01C464' : value < 3 ? '#FF4D4D' : '#FFB531'
    return (
        <div style={{display: 'flex'}}>
            {(new Array(value)).fill('').map((_, i, {length}) =>
                <div key={i} style={{
                    display: 'inline-block',
                    background: color,
                    height: '5px',
                    width: '23px',
                    borderRadius: `${i === 0 ? '6px' : '0px'} ${i === length - 1 ? '6px' : '0px'} ${i === length - 1 ? '6px' : '0px'} ${i === 0 ? '6px' : '0px'}`,
                    marginRight: '2px',
                }}/>
            )}
        </div>
    )
}

const TargetInfo = ({user, onClick}: {
    onClick?: () => void
    user: ShortUserInfoFragment & UserScoreFragment
}) => {
    const click = useStop(onClick)
    const openRating = useStop(() => {
        void openReviewsList.act({from: 'review', user, mode: 'author'})
    })
    const [job] = User.getJobs(user)
    return (
        <Box display="flex" onClick={click} alignItems="flex-start">
            <AvatarImage avatar={user.user_image} size={84} bRadius={12} bColor={undefined}/>
            <Gap size="s12"/>
            <Box minWidth="s0" display="flex" flexDirection="column" flexShrink={1}>
                <Box minWidth="s0" display="flex" alignItems="center">
                    <ClampLines clamp={2}>
                        <Line inline overflow="wrap" role="16/22" weight={700} text={User.getName(user)}/>
                    </ClampLines>
                    {!!user.user_score.mean_grade && (
                        <Box display="flex" onClick={openRating} alignItems="center">
                            <Rate size={17} style={{margin: '0 4px 1px 6px'}} />
                            <Line role="16/22" weight={700} text={toFixed(user.user_score.mean_grade)} />
                        </Box>
                    )}
                </Box>
                <Box style={{marginTop: '2px'}} />
                {'user_info' in user ? <>
                    {job && <Line role="14/18" weight={500} color="neutral/500" text={<JobLine job={job} linkStyle={{color: '#587FFE'}} />} />}
                    <Box style={{marginTop: '2px'}} />
                    <Line role="14/18" weight={500} color="neutral/500" text={user.user_info.location.name}/>
                </> : <>
                    <Line role="14/18" weight={500} color="neutral/500" text={tr.profile.invited}/>
                </>}
            </Box>
        </Box>
    )
}
